import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useEnvironments } from "../Providers/EnvironmentContext";

export function useApi() {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    async function getToken(scope) {
        try {
            const token = await instance.acquireTokenSilent({ scopes: [scope], account: accounts[0] });
            return token.accessToken;
        }
        catch (InteractionRequiredAuthError) {
            const token = await instance.acquireTokenRedirect({ scopes: [scope], account: accounts[0] });
            return token.accessToken;
        }
    };

    async function requestConsent(scope) {
        return await instance.acquireTokenRedirect({
            scopes: scope,
            account: accounts[0],
            prompt: "consent"
        });
    }

    async function execute(request) {
        if (!isAuthenticated) {
            return {
                ok: false,
                status: 401,
                statusText: 'Not authorized',
                data: null
            }
        }

        const { method, path, data, responseType } = request;
        const response = await fetch(`${process.env.REACT_APP_MANAGEMENT_API}${path}`,
            {
                method: method,
                headers: new Headers({
                    'Authorization': 'Bearer ' + await getToken(process.env.REACT_APP_MANAGEMENT_IMPERSONATION_ENDPOINT),
                    'Content-Type': 'application/json'
                }),
                body: data ? JSON.stringify(data) : null
            });

        async function getContent() {
            if (response.status === 204)
                return null;

            if (response.status === 202)
                return null;

            switch (responseType) {
                case 'json':
                    return await response.json();
                case 'blob':
                    return await response.blob();
                case 'text':
                    return await response.text();
                default:
                    return null;
            }
        }

        const result = {
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            data: await getContent()
        };


        if (!result.ok && result.status === 403 && result.data && result.data.scopes) {
            await requestConsent(result.data.scopes);
            return await execute(request);
        }

        return result;
    }

    return {
        callWebApiForUser: (request) => execute(request),
        environments: {
            get: () => execute({ method: 'GET', path: '/Instances', responseType: 'json' })
        },
        dataVerse: {
            discover: () => execute({ method: 'GET', path: '/v2/DataVerse/Discover', responseType: 'json' }),
            configure: (id, data) => execute({ method: 'POST', path: `/v2/DataVerse/${id}`, data: data, responseType: 'json' })
        },
        account: {
            isAuthenticated: isAuthenticated,
            get: () => execute({ method: 'GET', path: '/Account', responseType: 'json' }),
            photo: () => execute({ method: 'GET', path: '/Account/Photo', responseType: 'blob' })
        }
    }
}

export function environmentApi() {
    const { environmentId } = useEnvironments();
    const { callWebApiForUser: apiClient } = useApi();

    return {
        isReady: !!environmentId,
        client: async function (request) {
            if (!environmentId) {
                return {
                    status: 400,
                    statusText: "No environment selected",
                    ok: false,
                }
            }

            return await apiClient({
                method: request.method,
                path: `/${environmentId}${request.path}`,
                data: request.data,
                responseType: request.responseType
            });
        }
    }
}
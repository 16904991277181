import { Badge } from "reactstrap";

export function CardHeaderLogo({children}){
    return (<div className="card-header-logo">{children}</div>)
}

export function CardSubHeader({children}){
    return (<div className="card-sub-header">{children}</div>)
}

export function CardFooterButton({children}){
    return (<div className="card-footer-button">{children}</div>)
}

export function BadgeOutline(props){
    return (<Badge className="badge-outline" {...props} />);
}

export const Capabilities = {
    DonationLink: { label: "Donation Link", default: "donationLink"},
    Donation: { label: "Donation" },
    DonationAgreement: {label: "Donation Agreement" },
    PaymentLink: {label: "Payment Link", default: "paymentLink"},
    Payment: {label: "Payment"}
};
